import React from 'react';
import { Card, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    width: '100vw',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      margin: '25px 0 25px 0',
      height: '450px',
      width: '800px',
      borderRadius: '10px',
    },
  },
}));

const CardContent: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>{children}</Card>
    </div>
  );
};

export default CardContent;
