import React, { useEffect } from 'react';
import { useAuth } from './firebase/AuthContext';

const Signout: React.FC = () => {
  const { signout } = useAuth();

  useEffect(() => signout(), []);

  return null;
};

export default Signout;
