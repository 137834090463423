import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
});
const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>
        Copyright 2021 Studio Auckland - All rights reserved. Not for commercial
        use. You may sew items to sell.
      </p>
    </div>
  );
};

export default Footer;
