const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

interface IFirebaseVars {
  url: {
    EMAIL_LINK_URL: string;
  };
}

// Constants for email link verification
// Any custom domains need to be set in approved domains.
const prod = {
  url: {
    EMAIL_LINK_URL: 'https://app.studioauckland.com/finishLogin',
  },
};

const dev = {
  url: {
    EMAIL_LINK_URL: 'http://localhost:3000/finishLogin',
  },
};

export const getEnvDepVar = (): IFirebaseVars =>
  process.env.NODE_ENV === 'development' ? dev : prod;

export default firebaseConfig;
