import React from 'react';
import { Backdrop, Card, makeStyles } from '@material-ui/core';
import Button from './Button';
import { useError } from './context/ErrorContext';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
  card: {
    width: '400px',
    height: '100px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface IError {
  error: string;
}
const Error: React.FC<IError> = ({ error }) => {
  const classes = useStyles();
  const { clearError } = useError();

  return (
    <Backdrop classes={{ root: classes.backdrop }} open onClick={clearError}>
      <Card className={classes.card}>
        <p>{error} </p>
        <Button value="OK" onClick={clearError} variant="outlined" />
      </Card>
    </Backdrop>
  );
};

export default Error;
