import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import bagMeasurementImg from '../../images/bag-measurements.png';
import itemMeasurementImg from '../../images/item-measurements.png';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    width: '100%',
    fontFamily: 'Adorn Roman',
    textAlign: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '20%',
  },
  button: {
    width: '50%',
    height: '36px',
    background: 'ghostwhite',
    border: '1px solid lightgray',
    borderRadius: '10px 10px 0 0',
    fontSize: '18px',
    fontFamily: 'Adorn Roman;',
    cursor: 'pointer',

    '&:hover': {
      borderBottom: '2px solid lightgray',
    },
    '&.active': {
      borderBottom: 'none',
      background: 'transparent',
    },
  },
  diagram: {
    height: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: { width: '75%' },
});

const MeasurementDiagram: React.FC = () => {
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
    setIndex(idx);
  };

  return (
    <div className={classes.root}>
      <div className={classes.control}>
        <button
          className={`${classes.button} ${index === 0 && 'active'}`}
          type="button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleClick(e, 0)
          }
        >
          Bag
        </button>
        <button
          className={`${classes.button} ${index === 1 && 'active'}`}
          type="button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleClick(e, 1)
          }
        >
          Item or Gift
        </button>
      </div>
      <div className={classes.diagram}>
        <img
          className={classes.img}
          src={index === 0 ? bagMeasurementImg : itemMeasurementImg}
          alt="pouch measurement diagram"
        />
      </div>
    </div>
  );
};

export default MeasurementDiagram;
