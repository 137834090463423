import firebase from './firebase';
import 'firebase/firestore';

const firestore = firebase.firestore();

const userCollection = firestore.collection('user');

function validateEmail(email: string): Promise<boolean> {
  return userCollection
    .where('email', '==', email.toLowerCase())
    .get()
    .then((docs) => !docs.empty)
    .catch((error) => {
      throw new Error(error.code);
    });
}

export default validateEmail;
