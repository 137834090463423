import React from 'react';

import ContentCard from '../../shared/layout/card/CardContent';
import CardPanel from '../../shared/layout/card/CardPanel';
import PanelTitle from '../../shared/layout/card/PanelTitle';
import AuthForm from './AuthForm';
import AuthInstructions from './AuthInstructions';

const Auth: React.FC = () => (
  <ContentCard>
    <CardPanel style={{}}>
      <PanelTitle title="Get Activated" />
      <AuthInstructions />
    </CardPanel>
    <CardPanel style={{ background: 'ghostwhite' }}>
      <PanelTitle title="Request Activation" />
      <AuthForm />
    </CardPanel>
  </ContentCard>
);

export default Auth;
