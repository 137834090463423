import pouchCutCalc, { IPouchCutCalc } from './utils';

const useCalc = (
  height: string | null,
  width: string | null,
  depth: string | null
): IPouchCutCalc | null => {
  if (!!height && !!width && !!depth) {
    const numHeight = parseFloat(height);
    const numWidth = parseFloat(width);
    const numDepth = parseFloat(depth);

    return pouchCutCalc(numHeight, numWidth, numDepth);
  }

  return null;
};

export default useCalc;
