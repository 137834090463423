import React from 'react';
import CardPanel from '../../shared/layout/card/CardPanel';
import PanelTitle from '../../shared/layout/card/PanelTitle';
import MeasurementForm from './MeasurementForm';
import CardContent from '../../shared/layout/card/CardContent';
import MeasurementDiagram from './MeasurementDiagram';

const MeasurementCalc: React.FC = () => (
  <CardContent>
    <CardPanel style={{ background: 'ghostwhite' }}>
      <PanelTitle title="Fit Without Fail" />
      <MeasurementForm />
    </CardPanel>
    <CardPanel style={{}}>
      <MeasurementDiagram />
    </CardPanel>
  </CardContent>
);

export default MeasurementCalc;
