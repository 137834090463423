import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  message: {
    width: '75%',
  },
});
const AuthInstructions: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.message}>
        Fill out your email address and you will receive a link to log in
        immediately.
      </p>
    </div>
  );
};

export default AuthInstructions;
