import firebase from './firebase';
import 'firebase/auth';
import { getEnvDepVar } from './firebaseConfig';

const auth = firebase.auth();

const actionCodeSettings = {
  url: getEnvDepVar().url.EMAIL_LINK_URL,
  handleCodeInApp: true,
};

export const signoutUser = (): void => {
  auth.signOut();
};

export const emailLinkSend = (email: string): Promise<void> =>
  auth
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(() => {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
      window.localStorage.setItem('emailForSignIn', email);
    })
    .catch((error: firebase.FirebaseError) => {
      // TODO: Handle Error
      throw new Error(error.code);
    });

export const emailLinkVerify = (): boolean =>
  auth.isSignInWithEmailLink(window.location.href);

export const emailLinkComplete = (
  email: string
): Promise<firebase.User | null> =>
  auth
    .signInWithEmailLink(email, window.location.href)
    .then((result) => {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
      return result.user;
    })
    .catch((error: firebase.FirebaseError) => {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      signoutUser();
      throw new Error(error.code);
    });

export const authStateChange = (
  userSetter: React.Dispatch<
    React.SetStateAction<firebase.User | null | undefined>
  >
): firebase.Unsubscribe =>
  auth.onAuthStateChanged((user) => {
    if (user) {
      userSetter(user);
    } else {
      userSetter(null);
    }
  });

export default emailLinkSend;
