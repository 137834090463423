import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import step1 from '../../../images/instructions/step1.png';
import step2 from '../../../images/instructions/step2.png';
import step3 from '../../../images/instructions/step3.png';
import step4 from '../../../images/instructions/step4.png';
import step5 from '../../../images/instructions/step5.png';
import step6 from '../../../images/instructions/step6.png';
import step7 from '../../../images/instructions/step7.png';
import step7a from '../../../images/instructions/step7a.png';
import step7b from '../../../images/instructions/step7b.png';
import step8 from '../../../images/instructions/step8.png';
import step9 from '../../../images/instructions/step9.png';
import step10 from '../../../images/instructions/step10.png';
import { IPouchCutCalc } from '../../hooks/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '800px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Adorn Roman',
    color: '#58595B',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '12px',
  },
  diagram: {
    margin: '25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    margin: '25px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pg: {
    marginBottom: '0',
    paddingBottom: '0',
  },
  note: {
    border: '1px solid black',
    // margin: '25px',
    padding: '3px',
  },
  highlight: {
    border: '1px solid black',
    borderRadius: '10px',
    background: 'ghostwhite',
    padding: '6px',
  },
  figure: {
    display: 'flex',
    justifyContent: 'center',
  },
  dimension: {
    fontWeight: 'bold',
  },
}));

interface IInstructionDetailsMobile {
  cutResults: IPouchCutCalc;
}

const InstructionDetailsMobile: React.FC<IInstructionDetailsMobile> = ({
  cutResults,
}) => {
  useEffect(() => window.scrollTo(0, 0), []);
  const classes = useStyles();
  const { cornerCut, heightCut, stringCut, widthCut } = cutResults;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h1>Fit Without Fail</h1>
      </div>
      <div className={classes.step}>
        <div className={classes.description}>
          <div className={classes.highlight}>
            <p>
              Cut 2:{' '}
              <span className={classes.dimension}>{heightCut}&quot;</span> high
              by <span className={classes.dimension}>{widthCut}&quot;</span>{' '}
              wide
            </p>
            <p
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
                padding: '12px 0',
              }}
            >
              Cut Bottom Corners:{' '}
              <span className={classes.dimension}>{cornerCut}&quot;</span>{' '}
              square
            </p>
            <p>
              Cut 2 Drawstring Lengths:{' '}
              <span className={classes.dimension}>{stringCut}&quot;</span> each
            </p>
          </div>
          <p className={classes.pg}>From fabric, cut one front and one back:</p>
          <p className={classes.pg}>
            <span className={classes.dimension}>{heightCut}</span> inches high
            by <span className={classes.dimension}>{widthCut}</span> inches wide
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step1} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            Cut out the bottom two corners of each piece:
          </p>
          <p className={classes.pg}>
            <span className={classes.dimension}>{cornerCut}</span> inch squares
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step2} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            (Optional)
            <br />
            Affix your label or logo to the right side of the front main fabric
            piece.
          </p>
          <p className={classes.pg}>
            I recommend placing the bottom of your logo at or near the
            horizontal center, as shown by the dashed line. However, your pouch
            and logo size and shape may dictate a more suitable placement.
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step3} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.note}>
            Seam allowance is 1/2&quot;. Be sure to backstitch at the beginning
            and end of each stitching line.
          </p>
          <p className={classes.pg}>
            With right sides together and beginning at the top, stitch down
            1-1/2&quot;. Stop to leave a 1&quot; opening for the drawstring then
            start stitching again to the bottom. Repeat on opposite side.
          </p>
          <p className={classes.pg}>
            Stitch across the bottom, backstitching at the start and stop.
          </p>
          <p className={classes.pg}>Press the three seams open.</p>
          <p className={classes.pg}>
            (Optional)
            <br />
            Trim seams with pinking shears.
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step4} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            On the wrong side of the main fabric, draw two horizontal lines from
            the top cut edge. The first line is 1-1/4&quot; down and the second
            line is 2-1/2&quot; down.
          </p>
          <p className={classes.pg}>
            Draw these lines on both the front and back pieces
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step5} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            With right sides together, box the bottom corner by aligning the
            side seam with the bottom seam, making sure the pressed seams are
            open. Stitch the opening together with a 1/2&quot; seam allowance,
            backstitching at the beginning and the end. Repeat with the other
            corner.
          </p>
          <p className={classes.pg}>
            (Optional)
            <br />
            Trim seams with pinking shears.
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step6} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            Making sure the side seams are opened flat, make a casing on the
            wrong side by folding the top raw edge of the fabric to meet the
            1-1/4&quot; mark (Figure A.) and press.
          </p>
          <p className={classes.pg}>
            Fold a second time to meet the 2-1/2&quot; mark (Figure B.) and
            press.
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step7} alt="" height="300" />
        </div>
      </div>

      <div className={classes.figure}>
        <img src={step7a} alt="" width="800" />
      </div>

      <div className={classes.figure}>
        <img src={step7b} alt="" width="800" />
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            From the wrong side, topstitch 1/8&quot; along the folded edge of
            the casing.
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step8} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <div className={classes.highlight}>
            <p>
              Cut <span className={classes.dimension}>two (2)</span>{' '}
              drawstrings, each{' '}
              <span className={classes.dimension}>{stringCut}</span> inches
              long.
            </p>
          </div>
          <p className={classes.pg}>
            Using a bodkin or safety pin, thread one drawstring into a side
            opening, pulling it through the front drawstring channel. Go past
            the opposite side opening hole and continue through the back
            drawstring channel, coming out on the same side you started. The
            loose ends finish on the same side.
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step9} alt="" height="300" />
        </div>
      </div>

      <div className={classes.step}>
        <div className={classes.description}>
          <p className={classes.pg}>
            Follow the same directions to thread the second drawstring through
            the channel, except this time begin and end at the opposite side
            opening hole. Tie the drawstring ends together tightly.
          </p>
          <p className={classes.pg}>
            Turn your Storage Pouch right side out and admire your work!
          </p>
        </div>
        <div className={classes.diagram}>
          <img src={step10} alt="" height="300" />
        </div>
      </div>
      <div style={{ textAlign: 'center' }}>
        <p>
          If you have any questions, please email me at info@studioauckland.com
        </p>
      </div>

      <div
        className={classes.highlight}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '75%',
          margin: 'auto',
          marginBottom: '24px',
          textAlign: 'center',
        }}
      >
        <p>
          Share your finished pouch on social media using #FitWithoutFail and
          #StudioAuckland and tag @studioauckland. <br />I would love to see
          what you make!
        </p>
        <p>Follow me on all social media @studioauckland</p>
        <p>
          You may sell handmade-by-you items from this pattern on a small scale
          at your shop or website. Please give @StudioAuckland design credit in
          your social media posts. You may not sell or share this pattern in
          whole or in part. Video tutorials of this pattern are not allowed
          without permission. If you wish to teach from this pattern, please
          seek prior approval.
        </p>
      </div>
    </div>
  );
};

export default InstructionDetailsMobile;
