import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useCalc from '../hooks/useCalc';
import { IPouchCutCalc } from '../hooks/utils';
import InstructionDetails from './instruction-details/InstructionDetails';
import InstructionDetailsMobile from './instruction-details/InstructionDetailsMobile';

const useQuery = () => new URLSearchParams(useLocation().search);

const Instructions: React.FC = () => {
  const query = useQuery();
  const height = query.get('height');
  const width = query.get('width');
  const depth = query.get('depth');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const results = useCalc(height, width, depth);

  if (!results) return <Redirect to="/" />;

  return (
    <>
      {isMobile ? (
        <InstructionDetailsMobile cutResults={results as IPouchCutCalc} />
      ) : (
        <InstructionDetails cutResults={results as IPouchCutCalc} />
      )}
    </>
  );
};

export default Instructions;
