import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Auth from './auth/Auth';
import FinishLogin from './FinishLogin';
import { useError } from '../shared/context/ErrorContext';

const Unauthenticated: React.FC = () => {
  const { error } = useError();

  return (
    <Switch>
      <Route path="/auth">
        <Auth />
      </Route>
      <Route path="/finishLogin">
        {error ? <Redirect to="/auth" /> : <FinishLogin />}
      </Route>
      <Route path="/">
        <Redirect to="/auth" />
      </Route>
    </Switch>
  );
};

export default Unauthenticated;
