import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Authenticated from './authenticated/Authenticated';
import Error from './shared/Error';
import { useAuth } from './shared/firebase/AuthContext';
import { useError } from './shared/context/ErrorContext';
import Unauthenticated from './unauthenticated/Unauthenticated';
import Navbar from './shared/navbar/Navbar';
import Footer from './shared/Footer';

const useStyles = makeStyles({
  root: {
    padding: '0',
  },
});

const App: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const { error } = useError();

  return (
    <>
      <Navbar />
      <Container className={classes.root}>
        {user ? <Authenticated /> : <Unauthenticated />}
        {error && <Error error={error} />}
        <Footer />
      </Container>
    </>
  );
};

export default App;
