import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './shared/firebase/AuthContext';
import { ErrorProvider } from './shared/context/ErrorContext';

const AppProviders: React.FC = ({ children }) => (
  <BrowserRouter>
    <ErrorProvider>
      <AuthProvider>{children}</AuthProvider>
    </ErrorProvider>
  </BrowserRouter>
);

export default AppProviders;
