import React from 'react';
import { makeStyles, Button as MuiButton } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '75%',
    height: '56px',
    margin: '6px 0',
    background: 'white',
    borderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
}));

interface IButtonProps {
  value: string;
  type?: 'button' | 'reset' | 'submit';
  onClick?: () => void;
  disabled?: boolean;
  variant: 'contained' | 'outlined';
  style?: { [key: string]: string };
}
const Button: React.FC<IButtonProps> = ({
  value,
  type = 'button',
  onClick,
  disabled,
  variant,
  style,
  ...rest
}) => {
  const classes = useStyle();

  return (
    <MuiButton
      classes={{ root: classes.root }}
      size="large"
      variant={variant}
      type={type}
      onClick={onClick}
      disabled={!!disabled}
      style={style}
      {...rest}
    >
      {value}
    </MuiButton>
  );
};

export default Button;
