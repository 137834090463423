import React from 'react';
import { Button, makeStyles, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { useAuth } from '../firebase/AuthContext';
import logo from '../../images/logo.png';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const useStyles = makeStyles({
  root: {},
  title: {
    flexGrow: 1,
    fontFamily: 'Adorn Roman',
    color: '#58595B',
    marginLeft: '12px',
  },
  logo: {
    height: '50px',
  },
  btn: {
    fontFamily: 'Adorn Roman',
    color: '#58595B',
    marginLeft: '12px',
  },
});

const Navbar: React.FC = () => {
  const { user, signout } = useAuth();

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const actions = user
    ? [
        { display: 'Calculator', path: '/' },
        { display: 'Information', path: '/information' },
        { display: 'Sign Out', path: '/signout' },
      ]
    : [{ display: 'Login', path: '/auth' }];

  const Aactions = user ? (
    <>
      <Button
        className={classes.btn}
        color="inherit"
        onClick={() => history.push('/')}
      >
        Calculator
      </Button>
      <Button
        className={classes.btn}
        color="inherit"
        onClick={() => history.push('/information')}
      >
        Information
      </Button>
      <Button className={classes.btn} color="inherit" onClick={signout}>
        Sign Out
      </Button>
    </>
  ) : (
    <Button className={classes.btn} color="inherit">
      Login
    </Button>
  );

  return (
    <>
      {isMobile ? (
        <MobileNav logo={logo} actions={actions} />
      ) : (
        <DesktopNav logo={logo} actions={actions} />
      )}
    </>
  );
};

export default Navbar;
