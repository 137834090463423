import React, { useEffect, useState } from 'react';
import { emailLinkVerify, emailLinkComplete } from '../shared/firebase/auth';
import { useError } from '../shared/context/ErrorContext';

const FinishLogin: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { setError } = useError();

  useEffect(() => {
    if (emailLinkVerify()) {
      setLoading(true);
      const email = window.localStorage.getItem('emailForSignIn');

      if (!email) {
        setError(
          'Error: Unable to authenticate the email address. Please try requesting another verification link.'
        );
      } else {
        emailLinkComplete(email)
          .then(() => {
            // setUser(authUser);
            setLoading(false);
          })
          .catch((e) => {
            let message = 'Error: ';
            switch (e.message) {
              case 'auth/invalid-email':
                message += 'Unable to authenticate the email address.';
                break;
              case 'auth/invalid-action-code':
                message += 'The verification link has expired.';
                break;
              default:
                message += 'An unknown error has occured.';
            }

            message += ' Please try requesting another verification link.';
            setError(message);
          });
      }
    }
  }, []);

  if (loading) return <p>Loading...</p>;

  return null;
};

export default FinishLogin;
