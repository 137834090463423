import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../shared/Button';
import banner from '../../images/banner.png';

const useStyles = makeStyles({
  root: {
    margin: '12px',
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Adorn Roman',
    color: '#492810',
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    margin: '24px',
  },
});

const useQuery = () => new URLSearchParams(useLocation().search);

const Information: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const query = useQuery();

  const height = query.get('height');
  const width = query.get('width');
  const depth = query.get('depth');

  const isValid = height && width && depth;

  const handleOnClick = () => {
    if (isValid) {
      history.push(
        `instructions?height=${height}&width=${width}&depth=${depth}`
      );
    } else {
      history.push(`/`);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <h1>Fit Without Fail</h1>
      </div>

      {!isMobile && (
        <div className={classes.banner}>
          <img style={{ width: '100%' }} src={banner} alt="Fit without fail" />
        </div>
      )}

      <div className={classes.button}>
        <Button
          type="button"
          onClick={handleOnClick}
          value={isValid ? 'View Instructions' : 'Go to Calculator'}
          variant="contained"
          style={{ background: '#ACBECC' }}
        />
      </div>

      <p>
        Welcome to the Fit Without Fail Storage Pouch Calculator. You have
        unlimited access to this calculator as well as all changes and updates.
      </p>

      <h3>Let&#39;s Go</h3>
      <p>
        The calculator requires three measurements from your handbag or other
        item. Enter the maximum Height, Width and Depth. Click “Calculate” and
        follow the written instructions for cutting your fabric, the bottom
        corners, and the two drawstring lengths. Included in these dimensions is
        the breathing room needed so that your handbag or item doesn’t warp when
        stored.
      </p>

      <p>
        Continue to follow the written instructions and construct your storage
        pouch.
      </p>

      <h3>Things To Consider</h3>
      <p>
        If your bag has structured handles, take those into consideration when
        measuring the bag height. Do not allow the handles of the bag to press
        down. (Place removable straps inside the bag for storing.)
      </p>

      <p>
        To cover an item with a wide profile, such as a clutch, wallet or dopp
        kit, you might want the drawstring at the narrower side of your item. In
        this case, simply reverse the height and width measurements when
        entering them into the calculator.
      </p>

      <h3>Supplies</h3>

      <p>
        To properly protect your luxury bags and purses, use a breathable cotton
        fabric. White or cream is best to help avoid color transfer on leather
        or vinyl bags. Some ideal fabric choices include:
      </p>
      <ul>
        <li>Quilting cotton</li>
        <li>Linen</li>
        <li>Cotton flannel</li>
        <li>Cotton sheeting</li>
        <li>Unbleached muslin</li>
        <li>Cotton twill</li>
      </ul>

      <p>Some ideal drawstring choices include:</p>
      <ul>
        <li>Cotton twill tape (1/4&quot; to 3/8&quot;)</li>
        <li>Ribbon (satin or grosgrain)</li>
        <li>Soutache braid (aka mousetail)</li>
        <li>Braided cotton drawstring cord (3mm – 5mm)</li>
      </ul>

      <p>
        Since every calculation is unique, return to the calculator link, enter
        a new set of numbers and quickly sew another effortless storage pouch.
      </p>

      <div className={classes.button}>
        <Button
          type="button"
          onClick={handleOnClick}
          value={isValid ? 'View Instructions' : 'Go to Calculator'}
          variant="contained"
          style={{ background: '#ACBECC' }}
        />
      </div>
    </div>
  );
};

export default Information;
