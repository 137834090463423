import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Button from '../../shared/Button';

interface IState {
  units: string;
  height: string;
  width: string;
  depth: string;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  instructions: {
    fontFamily: 'Adorn Roman',
  },
  textField: {
    margin: '6px 0',
    width: '75%',
    backgroundColor: 'white',
    borderColor: '#148F77',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
      },
    },
    '& .Mui-focused': {
      borderColor: '#148F77',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0',
    },
  },
}));

const MeasurementForm: React.FC = () => {
  const [values, setValues] = useState<IState>({
    units: 'standard',
    height: '',
    width: '',
    depth: '',
  });
  const [isValid, setIsValid] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    // TODO Add validation for input as numbers or 2 dig decimal

    setIsValid(
      values.height !== '' && values.width !== '' && values.depth !== ''
    );
  }, [values]);

  const handleChange =
    (key: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((prevState) => ({
        ...prevState,
        [key]: event.target.value,
      }));
    };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    // Set route with query params
    history.push(
      `information?height=${values.height}&width=${values.width}&depth=${values.depth}`
    );
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.instructions}>
        <h3 style={{ margin: '0', textAlign: 'center' }}>
          To calculate the custom pouch dimensions
        </h3>
        <p style={{ margin: '0', textAlign: 'center' }}>
          measure the item&#39;s height, width and depth
        </p>
      </div>
      {/* <RadioGroup
        row
        aria-labelledby="unit-options-row-radio-button"
        name="row-radio-buttons-group"
        onChange={handleChange('units')}
        value={values.units}
      >
        <FormControlLabel
          value="standard"
          control={<Radio color="default" />}
          label="Standard"
        />
        <FormControlLabel
          value="metric"
          control={<Radio color="default" />}
          label="Metric"
        />
      </RadioGroup> */}
      <TextField
        classes={{ root: classes.textField }}
        variant="outlined"
        id="outlined-textfield-with-label-height"
        label="Item or Gift Height"
        value={values.height}
        onChange={handleChange('height')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {values.units === 'standard' ? 'in' : 'cm'}
            </InputAdornment>
          ),
        }}
        type="number"
      />
      <TextField
        classes={{ root: classes.textField }}
        variant="outlined"
        id="outlined-textfield-with-label-width"
        label="Item or Gift Width"
        value={values.width}
        onChange={handleChange('width')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {values.units === 'standard' ? 'in' : 'cm'}
            </InputAdornment>
          ),
        }}
        type="number"
      />
      <TextField
        classes={{ root: classes.textField }}
        variant="outlined"
        id="outlined-textfield-with-label-depth"
        label="Item or Gift Depth"
        value={values.depth}
        onChange={handleChange('depth')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {values.units === 'standard' ? 'in' : 'cm'}
            </InputAdornment>
          ),
        }}
        type="number"
      />
      <Button
        disabled={!isValid}
        value="Calculate"
        type="submit"
        variant="outlined"
      />
    </form>
  );
};

export default MeasurementForm;
