import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MeasurementCalc from './measurement-calc/MeasurementCalc';
import Instructions from './instructions/Instructions';
import Information from './information/Information';
import Signout from '../shared/Signout';

const Authenticated: React.FC = () => (
  <Switch>
    <Route exact path="/">
      <MeasurementCalc />
    </Route>
    <Route path="/finishLogin">
      <Redirect to="/" />
    </Route>
    <Route path="/instructions">
      <Instructions />
    </Route>
    <Route path="/information">
      <Information />
    </Route>
    <Route path="/signout">
      <Signout />
    </Route>
    <Route path="/auth">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default Authenticated;
