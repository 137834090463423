import React, { useState } from 'react';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  IconButton,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontFamily: 'Adorn Roman',
    color: '#58595B',
    margin: '12px',
    textAlign: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: '50px',
  },
  btn: {
    fontFamily: 'Adorn Roman',
    color: '#58595B',
    marginLeft: '12px',
  },
  list: {
    width: 250,
  },
}));

interface IMobileNavProps {
  actions: { display: string; path: string }[];
  logo: string;
}

const MobileNav: React.FC<IMobileNavProps> = ({ actions, logo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  function toggleDrawer() {
    setIsOpen((prevState) => !prevState);
  }

  const list = (
    <div className={classes.list} role="presentation" onClick={toggleDrawer}>
      <List>
        {actions.map((action) => (
          <ListItem
            button
            key={action.display}
            onClick={() => history.push(action.path)}
          >
            <ListItemText primary={action.display} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar position="static" color="transparent">
      <Container>
        <Toolbar>
          <img className={classes.logo} src={logo} alt="studio auckland logo" />
          <Typography variant="h6" className={classes.title}>
            Pouch Calculator
          </Typography>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon onClick={toggleDrawer} />
          </IconButton>
          <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
            {list}
          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MobileNav;
