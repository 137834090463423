import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
});

interface ICardPanelProps {
  style: { [key: string]: string };
}

const CardPanel: React.FC<ICardPanelProps> = ({ children, style }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      {children}
    </div>
  );
};

export default CardPanel;
