const roundToNearest = (num: number, toNearest: number) => {
  const roundTo = 1 / toNearest;
  return Math.round(num * roundTo) / roundTo;
};

const heightCutCalc = (height: number, depth: number) => {
  const excess = 4;
  return height + depth + excess;
};

const widthCutCalc = (width: number, depth: number) => {
  const excess = 3;
  return width + depth + excess;
};

const cornerCutCalc = (depth: number) => Math.ceil(depth / 0.5) * 0.25;

const calcString = (width: number) => width * 2.5;

export interface IPouchCutCalc {
  heightCut: number;
  widthCut: number;
  cornerCut: number;
  stringCut: number;
}

const pouchCutCalc = (
  height: number,
  width: number,
  depth: number
): IPouchCutCalc => {
  const heightCut = roundToNearest(heightCutCalc(height, depth), 1);
  const widthCut = roundToNearest(widthCutCalc(width, depth), 1);
  const cornerCut = roundToNearest(cornerCutCalc(depth), 0.25);
  const stringCut = roundToNearest(calcString(widthCut), 1);

  return {
    heightCut,
    widthCut,
    cornerCut,
    stringCut,
  };
};

export default pouchCutCalc;
