import React, { useContext, useEffect, useState } from 'react';
import firebase from 'firebase';
import { emailLinkSend, authStateChange, signoutUser } from './auth';
import validateEmail from './firestore';

interface IAuthContext {
  user: firebase.User | null;
  validateUserEmail: (email: string) => Promise<boolean>;
  sendVerificationLink: (
    email: string,
    onSuccess: () => void,
    onFail: () => void
  ) => Promise<void>;
  signout: () => void;
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = authStateChange(setUser);
    return () => unsubscribe();
  }, []);

  if (user === undefined) return <p>Loading...</p>;

  const validateUserEmail = (email: string) => validateEmail(email);
  const sendVerificationLink = (
    email: string,
    onSuccess: () => void,
    onFail: () => void
  ): Promise<void> =>
    emailLinkSend(email.toLowerCase()).then(onSuccess).catch(onFail);
  const signout = () => signoutUser();

  return (
    <AuthContext.Provider
      value={{
        user,
        validateUserEmail,
        sendVerificationLink,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used inside the AuthProvider');
  }
  return context;
};
