import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    height: '15%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  title: {
    fontFamily: 'Adorn Roman',
    color: '#58595B',
  },
});

interface IPanelTitleProps {
  title: string;
}

const PanelTitle: React.FC<IPanelTitleProps> = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className={classes.title}>{title}</h1>
    </div>
  );
};

export default PanelTitle;
