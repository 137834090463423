import React, { createContext, useContext, useState } from 'react';

interface IErrorContext {
  error: string | null;
  clearError: () => void;
  setError: (message: string) => void;
}

const ErrorContext = createContext<IErrorContext | undefined>(undefined);

export const ErrorProvider: React.FC = ({ children }) => {
  const [error, setErrorState] = useState<string | null>(null);

  const setError = (message: string) => {
    setErrorState(message);
  };
  const clearError = () => setErrorState(null);

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
        clearError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = (): IErrorContext => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useError must be used in the ErrorProvider');
  }
  return context;
};
