import React, { useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Button from '../../shared/Button';
import { useAuth } from '../../shared/firebase/AuthContext';

const useStyles = makeStyles({
  form: {
    width: '100%',
    height: '85%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textField: {
    width: '75%',
    background: 'white',
    marginTop: '36px',
    marginBottom: '36px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `10px`,
      },
    },
  },
  alert: {
    width: '75%',
  },
});

const ERROR_MESSAGE =
  "It looks like you don't have access to this product. If you reacently purchased the item it may take upto 10 minutes to process your order.";

const AuthForm: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<string>();

  const classes = useStyles();

  const { validateUserEmail, sendVerificationLink } = useAuth();

  useEffect(() => {
    const emailRegex = /\S+@\S+\.\S+/;
    setIsValid(emailRegex.test(email));
  }, [email]);

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    // Clear any old errors or messages
    setMessage(undefined);
    setError(undefined);

    validateUserEmail(email).then((valid) => {
      if (valid) {
        sendVerificationLink(
          email,
          () =>
            setMessage(
              'The activation link has been sent to the provided email.'
            ),
          () => setError(ERROR_MESSAGE)
        );
      } else {
        setError(
          'An authentication error has occured. If you should have access to this application, please try again or contact us for help.'
        );
      }
    });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      {message && (
        <Alert className={classes.alert} severity="success">
          {message}
        </Alert>
      )}
      {error && (
        <Alert className={classes.alert} severity="error">
          {error}
        </Alert>
      )}
      <TextField
        classes={{ root: classes.textField }}
        variant="outlined"
        id="outlined-textfield-with-label-height"
        label="E-Mail"
        value={email}
        onChange={handleChange()}
      />
      <Button
        disabled={!isValid}
        value="Request Activation Link"
        type="submit"
        variant="outlined"
      />
    </form>
  );
};

export default AuthForm;
