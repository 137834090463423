import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  title: {
    flexGrow: 1,
    fontFamily: 'Adorn Roman',
    color: '#58595B',
    marginLeft: '12px',
  },
  logo: {
    height: '50px',
  },
  btn: {
    fontFamily: 'Adorn Roman',
    color: '#58595B',
    marginLeft: '12px',
  },
});

interface IDesktopNavProps {
  actions: { display: string; path: string }[];
  logo: string;
}

const DesktopNav: React.FC<IDesktopNavProps> = ({ actions, logo }) => {
  const classes = useStyles();
  const history = useHistory();
  const list = actions.map((action) => (
    <Button
      key={action.display}
      className={classes.btn}
      color="inherit"
      onClick={() => history.push(action.path)}
    >
      {action.display}
    </Button>
  ));

  return (
    <AppBar position="static" color="transparent">
      <Container>
        <Toolbar>
          <img className={classes.logo} src={logo} alt="studio auckland logo" />
          <Typography variant="h6" className={classes.title}>
            Pouch Calculator
          </Typography>
          {list}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default DesktopNav;
